export enum Action {
  CREATE = 'create',
  UPDATE = 'edit',
  DELETE = 'delete',
}

export enum StatusColors {
  NEW = '#f9f871',
  DRAFT = '#fff6fe',
  ACCENT = '#00dfbd',
  INACTIVE = '#cec7cc',
  ACTIVE = '#efefdc',
  PENDING = '#efe8fb',
  WARN = '#ffcccc',
  COMPLETE = '#e6ffe6',
  ATTENTION = '#C982E8',
}
